import React from "react";
import PropTypes from "prop-types";
import Modal from 'react-modal';

import ButtonClose from '../buttonClose';

import './modals.scss';

import contents from "./modals.yml";

import {useCookies} from "react-cookie";

Modal.setAppElement(`#___gatsby`);
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,.7)';

export const ModalYoutube = ({ modalIsOpen, closeModal, ...props }) => {

  const {
    onClick,
    videoID,
    label
  } = props;

  // eslint-disable-next-line no-console
  const [cookies, setCookie] = useCookies();
  const youtubeDomain =
    (cookies.ck_analytics !== "Y" || cookies.ck_targeting !== "Y")
    ? "https://www.youtube-nocookie.com"
    : "https://www.youtube.com";

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="modal-class"
      contentLabel={label}
      closeTimeoutMS={300}
    >
      <ButtonClose
        onClick={onClick}
      />
      <div className="video__wrapper">
        <iframe title={contents.seeYoutubeVideo} src={`${youtubeDomain}/embed/${videoID}?autoplay=0&controls=1&modestbranding=1&showinfo=0&loop=1&enablejsapi=1&playerapiid=player&rel=0`} frameBorder="0" allowFullScreen={true} />
      </div>
    </Modal>

  )
};

ModalYoutube.propTypes = {
  onClick: PropTypes.func.isRequired,
  videoID: PropTypes.string,
  label: PropTypes.string.isRequired
};
